import React, {useContext} from 'react'
import { GlobalDataContext } from '../../context/context';

const AboutPage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <div className="contact-g-map">
        <h2 className="text-center">We Cover {rpdata?.dbPrincipal?.miles} Miles Of The Surrounding Counties Of:</h2>
        
        <div className='cont-map '>
          <div className="row justify-content-center pd-top-50">
            <div className="col-md-4">
              <h3 className='text-center'>{rpdata?.dbPrincipal?.location[0].address}</h3>
              <iframe src={rpdata?.dbPrincipal?.location[0].url} />
            </div>
            <div className="col-md-4">
              <h3 className='text-center'>{rpdata?.dbPrincipal?.location[1].address}</h3>
              <iframe src={rpdata?.dbPrincipal?.location[1].url} />
            </div>
            <div className="col-md-4">
              <h3 className='text-center'>{rpdata?.dbPrincipal?.location[2].address}</h3>
              <iframe src={rpdata?.dbPrincipal?.location[2].url} />
            </div>
            <div className="col-md-6">
              <h3 className='text-center'>{rpdata?.dbPrincipal?.location[3].address}</h3>
              <iframe src={rpdata?.dbPrincipal?.location[3].url} />
            </div>
            <div className="col-md-6">
              <h3 className='text-center'>{rpdata?.dbPrincipal?.location[4].address}</h3>
              <iframe src={rpdata?.dbPrincipal?.location[4].url} />
            </div>
          </div>
        </div>
        
      </div>
    </div>
}

export default AboutPage
