import React from "react";
import BotonWhatsapp from "./botonWhatsapp/BotonWhatsapp";

const LiveChat = () => {
  return (
    <div>
      <BotonWhatsapp />
      <div className="visor_Counter">
        <div class="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
      </div>
    </div>
  );
};

export default LiveChat;
